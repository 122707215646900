import MDinput from '@/components/MDinput';
import { validatorRequire } from '@/utils/validators';
import PickRec from '@/components/DSE/PickRec';
import NumericInput from '@/components/DSE/NumericInput';

export function getFormDefinition(vue) {
  return {
    name: {
      type: MDinput,
      defaultValue: '',
      props: {
        maxlength: 64,
        required: true
      },
      rules: [{ validator: validatorRequire }]
    },
    restaurantID: {
      type: PickRec,
      defaultValue: null,
      props: {
        caption: 'common.restaurant',
        valueField: 'id',
        labelField: 'name',
        inputField: 'name',
        maxlength: 64,
        columns: [
          { field: 'name', label: 'common.restaurant' },
          { field: 'code', label: 'common.code' }
        ],
        entity: 'Restaurant',
        required: true,
        usePagination: true,
        hideInput: false,
        suggest: true
      },
      rules: [{ validator: validatorRequire }]
    },
    position: {
      type: NumericInput,
      defaultValue: 0,
      props: {
        type: 'number',
        precision: 0,
        caption: 'common.positionInCashDesk'
      }
    }
  };
}

export default getFormDefinition;

export function createItemFromFormData(vue, form) {
  return form;
}

export function createFormDataFromItem(vue, item) {
  return {
    ...item
  };
}
