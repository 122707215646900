<template>
  <div class="pickrec-wrapper" :class="{ 'has-label': !!$slots.default, 'is-suggest': suggest, 'has-value': hasValue }">
    <div>
      <div class="controls">
        <div class="input-wrapper">
          <div v-if="!hideInput && !hasValue">
            <MDinput
              v-if="suggest"
              ref="input"
              v-model="inputModel"
              :fetch-suggestions="querySearchAsync"
              type="autocomplete"
              :disabled="disabled"
              :readonly="readonly"
              :maxlength="maxlength"
              name="inputValue"
              :value-key="inputField"
              :required="required"
              :trigger-on-focus="false"
              popper-class="pickrec-popper"
              @input="handleInput"
              @select="handleAutocompleteSelect"
            >
              <slot />
              <template v-slot:autocomplete="item">
                <suggestion-item
                  :class="item.class"
                  :content="item[inputField]"
                  :highlight="item.isSystemOption ? '' : popperSearchTerm"
                ></suggestion-item>
              </template>
            </MDinput>
            <MDinput
              v-else
              ref="input"
              v-model="inputModel"
              :disabled="disabled"
              :readonly="readonly"
              :maxlength="maxlength"
              name="inputValue"
              :value-key="inputField"
              :required="required"
              @input="handleInput"
            >
              <slot />
            </MDinput>
          </div>
          <div v-else style="margin-top: 4px" class="label-container">
            <span v-if="currentLabel != '' || loading" class="selected pickrec-label">
              <i v-if="loading" class="el-icon-loading"></i>
              <router-link v-else :class="labelClassNames" :to="routerLinkTo">
                <strong @click="handleClickLabel">{{ currentLabel }}</strong>
              </router-link>
              <span v-if="!disabled && !readonly" class="label-clear">
                <i class="el-icon-circle-close" @click="clear()"></i>
              </span>
            </span>
          </div>
        </div>
        <div class="pickrec-info">
          <div>
            <el-button
              v-show="!disabled && !readonly"
              v-if="!hideSearchOnValue || !hasValue"
              ref="searchBtn"
              type="primary"
              icon="el-icon-search"
              size="mini"
              :disabled="disabled"
              @click="openDialog"
            />
            <el-button
              v-if="!disabled && !readonly && onCreate"
              type="primary"
              icon="el-icon-circle-plus"
              size="mini"
              :disabled="disabled"
              @click="onCreate()"
            />
            <slot name="buttons" />
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :title="$t('pickrec.chooseEntryFor', { caption: $t(caption) })"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :fullscreen="openInFullscreen"
      append-to-body
    >
      <CrudTable
        v-if="dialogVisible"
        ref="table"
        :entity="entity"
        :columns="columns"
        :item-filter="internalItemFilter"
        :filters="tableFilters"
        :requires-domain-id="true"
        :allow-actions="false"
        :persist-state="false"
        is-embedded
        :on-row-dbl-click="() => {}"
        :use-pagination="usePagination"
        @input="onTableRowDoubleClick"
      />
    </el-dialog>
  </div>
</template>

<script>
import CrudTable from '@/components/crud/CrudTable';
import MDinput from '@/components/MDinput';
import SuggestionItem from './SuggestionItem';
import { entityToStore } from '@/utils/store';
import { objectsAreEqual, isEqual } from '@/utils';
const debounce = require('lodash.debounce');

export default {
  name: 'PickRec',
  components: {
    CrudTable,
    MDinput,
    SuggestionItem
  },
  props: {
    caption: String,
    disabled: {
      default: false,
      type: Boolean
    },
    readonly: {
      default: false,
      type: Boolean
    },
    inputField: {
      // Field name of item that is used to look up an item in input
      default: 'id',
      type: String
    },
    columns: {
      default: () => [],
      type: Array
    },
    storeLoader: {
      type: Function
    },
    entity: String,
    value: [String, Number, Array],
    label: Function,
    labelField: {
      // Field name of item that is displayed as label, eg. name
      default: 'value',
      type: String
    },
    maxlength: {
      default: 255,
      type: Number
    },
    openInFullscreen: {
      default: false,
      type: Boolean
    },
    required: {
      default: false,
      type: Boolean
    },
    valueField: {
      // Field name of item that is used in form, eg. userID
      type: String,
      default: 'id'
    },
    itemFilter: Function,
    usePagination: {
      type: Boolean,
      default: false
    },
    fetchByInput: {
      type: Boolean,
      default: false
    },
    suggest: {
      type: Boolean,
      default: true
    },
    suggestionLimit: {
      type: Number,
      default: 10
    },
    filters: {
      type: Object,
      default: () => ({})
    },
    requestParams: {
      type: Object,
      default: () => ({})
    },
    monitorStoreItem: Boolean,
    disableLink: Boolean,
    onOpen: Function,
    onCreate: Function,
    hideInput: Boolean,
    initialValue: [String, Number],
    emptyValue: {
      type: [Number, String],
      default: null
    },
    hideSearchOnValue: Boolean,
    locationId: Number
  },
  data() {
    return {
      currentInput: '',
      currentLabel: '',
      currentValue: this.value,
      currentItem: null,
      dialogVisible: false,
      inputError: false,
      inputModel: '',
      loading: false,
      valueIsUpToDate: false,
      tableFilters: this.filters,
      filtersOnLastFetch: {},
      itemsUpdateQuery: null,
      popperSearchTerm: '',
      items: []
    };
  },
  computed: {
    xsSpanLeft() {
      if (this.onCreate) {
        return 10;
      }
      return 20;
    },
    xsSpanRight() {
      if (this.onCreate) {
        return 14;
      }
      return 3;
    },
    smSpanLeft() {
      if (this.onCreate) {
        return 15;
      }
      return 20;
    },
    smSpanRight() {
      if (this.onCreate) {
        return 8;
      }
      return 3;
    },
    routerLinkTo() {
      return this.currentValue && !this.disableLink && !this.onOpen
        ? { name: 'Edit' + this.entity, params: { id: this.currentValue } }
        : '';
    },
    labelClickHasAction() {
      return !!this.routerLinkTo || !!this.onOpen;
    },
    labelClassNames() {
      return this.labelClickHasAction ? '' : 'has-no-action';
    },
    entityStoreName() {
      return entityToStore(this.entity);
    },
    storeItem() {
      return this.$store.getters[this.entityStoreName + '/getLooseItemByID'](this.value);
    },
    labelIsUpdated() {
      if (!this.currentValue) {
        return this.currentLabel === '';
      }
      return this.currentItem && this.currentItem[this.valueField] === this.currentValue;
    },
    internalItemFilter() {
      return this.itemFilter ? this.itemFilter : item => true;
    },
    fuzzyTotal() {
      return this.itemFilter && this.suggestionLimit < this.itemsTotal;
    },
    hasValue() {
      return !!this.currentValue;
    }
  },
  watch: {
    currentValue(newValue, oldValue) {
      if (this.entityStoreName) {
        this.inputError = false;
        if (newValue === this.emptyValue) {
          this.currentItem === null;
        } else if (!this.currentItem || this.currentItem[this.valueField] !== newValue) {
          this.debouncedRefreshItem();
        }
      }
    },
    value(value) {
      if (this.currentValue !== value) {
        this.valueIsUpToDate = false;
        this.currentValue = value;
        if (value === this.emptyValue) {
          this.clear();
        }
      }
    },
    currentItem(item) {
      if (item) {
        this.currentLabel = this.label ? this.label(item, this) : item[this.labelField];
        this.inputModel = item[this.inputField];
        this.$emit('input', item[this.valueField]);
      } else {
        this.currentLabel = '';
        this.inputModel = '';
        this.$emit('input', this.emptyValue);
      }
    },
    storeItem(storeItem, oldStoreItem) {
      if (this.monitorStoreItem && !isEqual(this.currentItem, storeItem)) {
        this.currentItem = storeItem;
      }
    },
    filters(filters) {
      this.tableFilters = Object.assign({}, this.tableFilters, filters);
    }
  },
  created() {
    if (this.suggest && this.fetchByInput) {
      throw new Error('fetchByInput will be ignored because suggest is set to true');
    }
    this.debouncedRefreshItem = debounce(this.refreshItem, 500);
    this.debouncedFetchItems = debounce(this.fetchItems, 500);
    if (this.value !== this.emptyValue) {
      this.refreshItem();
    }
  },
  methods: {
    handleClickLabel() {
      if (this.onOpen) {
        this.onOpen();
      }
    },
    transformItemToAutocompleteObject(item) {
      const obj = {
        ...item
      };
      return obj;
    },
    openDialogSearch(queryString) {
      const searchFilters = {};
      searchFilters[this.inputField] = queryString;
      return () => {
        this.tableFilters = Object.assign(this.filters, searchFilters);
        this.openDialog();
      };
    },
    generateSeeMoreOption(restCount, queryString) {
      const option = {};
      option[this.inputField] = this.fuzzyTotal
        ? this.$i18n.t('pickrec.suggestionsMayBeMore')
        : this.$i18n.t('pickrec.suggestionsRestCount', { count: restCount });
      option[this.valueField] = this.emptyValue;
      option.preventValueChange = true;
      option.class = 'suggestions-summary';

      option.action = this.openDialogSearch(queryString);
      option.isSystemOption = true;
      return option;
    },
    generateNoDataOption() {
      const option = {};
      option[this.inputField] = this.$i18n.t('pickrec.itemNotFound', { caption: this.$i18n.t(this.caption) });
      option[this.valueField] = this.emptyValue;
      option.preventValueChange = true;
      option.class = 'suggestions-summary';
      option.isSystemOption = true;
      return option;
    },
    querySearchAsync(queryString, cb) {
      const query = { ...this.filters };
      if (this.initialValue) {
        query.includeID = this.initialValue;
      }
      query[this.inputField] = queryString;
      query.limit = this.suggestionLimit;
      query.sort = this.inputField;
      query.page = 1;
      this.popperSearchTerm = this.inputModel;
      this.debouncedFetchItems(query, () => {
        if (this.inputModel !== queryString) {
          // Close suggestions
          return cb([]);
        }
        const suggestions = this.items.reduce((objs, item) => {
          objs.push(this.transformItemToAutocompleteObject(item));
          return objs;
        }, []);
        if (!suggestions.length) {
          suggestions.push(this.generateNoDataOption());
        } else if (this.itemsTotal > this.suggestionLimit) {
          suggestions.push(this.generateSeeMoreOption(this.itemsTotal - suggestions.length, queryString));
        }
        cb(suggestions);
      });
    },
    handleAutocompleteSelect(item) {
      if (item.preventValueChange) {
        this.$nextTick(() => {
          this.inputModel = this.currentItem ? this.currentItem[this.inputField] : '';
        });
      } else {
        this.currentItem = item;
      }
      if (item.action) {
        item.action();
      }
    },
    focus() {
      const inputEl = this.$refs.input;
      const searchBtnEl = this.$refs.searchBtn;
      if (inputEl) {
        inputEl.focus();
      } else if (searchBtnEl) {
        searchBtnEl.$el.focus();
      }
    },
    openDialog() {
      this.dialogVisible = true;
      if (!objectsAreEqual(this.tableFilters, this.tableFiltersOnLastFetch)) {
        // Refresh when filters have changed
        this.$refs.table && this.$refs.table.refreshList();
        this.tableFiltersOnLastFetch = this.tableFilters;
      }
    },
    clear() {
      this.currentItem = null;
    },
    handleInput(value) {
      if (this.suggest) {
        return;
      }
      this.valueIsUpToDate = false;
      if (value === '' || this.valueField === this.inputField) {
        this.currentValue = value;
      } else {
        if (this.fetchByInput) {
          this.debouncedRefreshItem(true);
        } else {
          let foundItem = false;
          const searchItems = value => {
            for (let i = 0; i < this.items.length; i++) {
              // Iterate through loaded items to look for input
              if (this.items[i][this.inputField].toString() === value) {
                this.currentValue = this.items[i][this.valueField];
                foundItem = true;
                break;
              }
            }
            this.inputError = !foundItem;
          };
          const query = { ...this.filters };
          if (this.initialValue) {
            query.includeID = this.initialValue;
          }
          if (objectsAreEqual(query, this.itemsUpdateQuery)) {
            searchItems(value);
          } else {
            this.debouncedFetchItems(query, () => {
              searchItems(value);
            });
          }
        }
      }
    },
    onTableRowDoubleClick(row) {
      this.valueIsUpToDate = false;
      this.currentItem = row;
      this.dialogVisible = false;
    },
    fetchItems(query, cb) {
      this.loading = true;
      const options = { dontSave: true };
      const itemsUpdateQuery = Object.assign({}, query);
      return this.$store
        .dispatch(this.entityStoreName + '/getItems', { ...this.requestParams, query, options })
        .then(data => {
          this.itemsUpdateQuery = itemsUpdateQuery;
          this.items = data.items.filter(item => this.internalItemFilter(item, this));
          this.itemsTotal = data.total;
          cb();
        })
        .finally(_ => {
          this.loading = false;
        });
    },
    refreshItem(refreshByInput) {
      if (this.valueIsUpToDate) {
        return;
      }

      const inactiveFilter = {};

      const identifier = {};
      if (refreshByInput) {
        identifier[this.inputField] = this.inputModel;
      } else {
        identifier.id = this.currentValue;
        inactiveFilter.includeID = this.currentValue;
      }

      if ((!refreshByInput && this.currentValue) || (refreshByInput && this.inputModel)) {
        this.inputError = false;
        this.loading = true;
        this.$store
          .dispatch(this.entityStoreName + '/getSimpleItem', {
            ...this.requestParams,
            selector: identifier,
            query: { ...inactiveFilter },
            options: { dontSave: true }
          })
          .then(item => {
            if (!this.internalItemFilter(item, this)) {
              throw new Error();
            }
            this.inputError = false;
            this.valueIsUpToDate = true;
            this.currentItem = item;
          })
          .catch(() => {
            this.inputError = true;
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.currentItem = null;
      }
    }
  }
};
</script>

<style lang="scss">
.pickrec-popper {
  .el-autocomplete-suggestion__wrap {
    max-height: 450px;
  }
}
.has-label.is-suggest {
  .pickrec-info {
    margin-top: 10px;
  }
}
</style>
<style lang="scss" scoped>
.suggestions-summary {
  font-size: 0.9em;
  text-align: center;
  color: #c03639;
}
.label-container {
  .label-clear {
    opacity: 0;
    cursor: pointer;
    margin-left: 5px;
  }
  &:hover {
    .label-clear {
      opacity: 1;
    }
  }
  .has-no-action {
    cursor: unset;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
    -webkit-user-drag: none;
  }
}
.pickrec-info > div {
  padding-top: 0px;
  span {
    margin-left: 6px;
    display: inline-block;
  }
}
.controls {
  display: flex;
  > div {
    display: inline-block;
  }
  .input-wrapper {
    flex-grow: 1;
  }
}
.has-value {
  .controls {
    .input-wrapper {
      flex-grow: 0;
    }
  }
}
.selected {
  i {
    margin-right: 5px;
  }
}
.fetch-error {
  color: #f56c6c;
  font-size: 12px;
  display: block;
}
.pickrec-label {
  font-size: 12px;
}
</style>
