<template>
  <div v-html="displayedContent"></div>
</template>

<script>
function boldString(str, find) {
  let bold;
  try {
    const re = new RegExp(`(${escapeSpecialCharacters(find)})`, 'gi');
    bold = str.replace(re, '<b>' + '$1' + '</b>');
  } catch (err) {
    bold = str;
  }
  return bold;
}
function escapeSpecialCharacters(str) {
  const specialCharacters = ['[', ']', '{', '}', '-', '+', '/', '.', '*', '^', '\\'];
  const explodedStr = str.split('');
  let escapedStr = '';
  let char;
  while ((char = explodedStr.shift())) {
    escapedStr += specialCharacters.includes(char) ? '\\' + char : char;
  }
  return escapedStr;
}
export default {
  props: {
    highlight: [String, Number],
    content: [String, Number]
  },
  computed: {
    displayedContent() {
      return this.highlight ? boldString(this.content, this.highlight) : this.content;
    }
  }
};
</script>

<style lang="scss" scoped></style>
